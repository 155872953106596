import classnames from "classnames";
import Image from "next/image";
import PropTypes from "prop-types";


const buttonStyles = {
  primary:
    "bg-primary-900 hover:bg-primary-800 focus:bg-primary-800 active:bg-primary-800 visited:bg-primary-800 text-white",
  ghost:
    "text-primary-900 outline outline-1 outline-primary-800 hover:bg-primary-50 focus:bg-primary-50 focus:outline-2 active:bg-primary-100 visited:bg-primary-100 ",
  borderLess:
    "text-primary-900 hover:text-primary-800 focus:text-primary-800 hover:bg-primary-100 focus:bg-primary-100 focus:outline focus:outline-primary-800 focus:outline-2 active:bg-primary-100 visited:bg-primary-100 ",
};

const sizes = {
  extraSmall: "py-2 px-6 text-sm",
  small: "py-2 lg:py-3 px-3 lg:px-6 text-sm ",
  medium: "py-3.5 px-6 md:px-8 text-base md:text-lg",
  large: "py-4 px-8 text-xl",
};

const iconPositions = {
  left: "flex-row-reverse space-x-reverse",
  right: "flex-row",
};

const iconSize = {
  extraSmall: "p-2 ",
  small: "p-2",
  medium: "p-3",
  large: "p-4",
};

const shadowbox = {
  extraSmall: "shadow-xs",
  small: "shadow-sm",
  medium: "shadow-md",
  large: "shadow-lg"
}

function Button({
  icon,
  iconPosition,
  label,
  onClick,
  type,
  style,
  fullWidth,
  className,
  disabled,
  size,
  btnClass,
  shadow,

}) {
  const defaultStyle = buttonStyles[style] || buttonStyles.primary;

  const buttonClasses = classnames(
    "rounded font-semibold disabled:bg-primary-100 disabled:text-primary-300 disabled:outline-none transform transition-transform active:scale-90 duration-300 flex items-center justify-center gap-x-2",
    defaultStyle, shadowbox[shadow],
    fullWidth && "w-full justify-center",
    className,
    btnClass,
    !icon || label ? sizes[size] : iconSize[size],
    {
      [iconPositions[iconPosition]]: iconPosition,
    }
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonClasses}`}
      disabled={disabled}
    >
      {label && (
        <span className={classnames({ [className]: icon })}>{label}</span>
      )}
      {icon && (
        <div className="flex items-center justify-center flex-none">
          <Image
            src={icon}
            width={size === "extraSmall" ? 16 : 18}
            height={size === "extraSmall" ? 16 : 18}
            alt="icon"
          />
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  style: PropTypes.oneOf(Object.keys(buttonStyles)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  iconPosition: PropTypes.oneOf(Object.keys(iconPositions)),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  btnClass: PropTypes.string,
  btnIconHeight: PropTypes.string,
  btnIconWidth: PropTypes.string,
  shadow: PropTypes.oneOf(Object.keys(shadowbox)),
};

Button.defaultProps = {
  icon: null,
  iconPosition: iconPositions.left,
  label: "",
  onClick: () => { },
  type: "button",
  style: "primary",
  fullWidth: false,
  className: "",
  disabled: false,
  size: "medium",
  btnClass: "",

};

export default Button;
