import React from "react";
import PropTypes from "prop-types";
const Container = ({ children, type ,className, ...property }) => {

  const containerStyle = {
    type1: "w-11/12 md:max-w-[1200px] mx-auto ",
    type2: "w-full",
  };

  return (
    <div
      className={` ${containerStyle[type]} ${className || ""}`}
      {...property}
    >
      {children}
    </div>
  );
};

export default Container;

Container.defaultProps = {
    children: 'Heading',
    className: '',
    type: 'type1',
  };

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['type1', 'type2'])
};
