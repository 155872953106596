import VerticalMenu from "atoms/VerticalMenu";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import InputBox from "atoms/InputBox";
import Button from "atoms/Button";
import Logo from "atoms/Logo";
import Image from "next/image";
import Container from "atoms/Container";
import { useRouter } from "next/router";
import { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupValidator } from "components/utils/yup-validator";
const axios = require('axios');
import Toast from "atoms/Toast";

const Footer = () => {
    const [showToast, setShowToast] = useState(false);
    const apiToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNGQ4ZDBkNWEwNmUxNTY0ZjIzOWU5ZWJiNTRjM2RjN2NiMTkzNDAwMTBkOGQyZjRkYWVmMGNjZDJlYWI2ZmUzYjM3YmIzZGJjNDk4MjIwNDYiLCJpYXQiOjE3MDY1MzQ3NTEuMTcwNjQ0LCJuYmYiOjE3MDY1MzQ3NTEuMTcwNjQ3LCJleHAiOjQ4NjIyMDgzNTEuMTQwOTQ4LCJzdWIiOiI4MTI4NTIiLCJzY29wZXMiOltdfQ.WiOjlW-YqEzjRL3rGpJruBTGWhfTIYI4W5LUYPFBRsSm437VAJ2cq_YdFFpKveVl4rymAzK3tVAhYrJP2daQXI9KIK_pQ8rMSh47VnEZQHMzj97gEg9t1KtqadYTBqXeFdb_VtYTiq_7E9SjRjV9715wJbr4o04JkuWfViL5RCgRZAAYtJfMCnOQVQMkqAfqjPsG_7TVeV1KfToyIP2x8Pfv_llPW9Ghgvjn-9f6pX8NTnxxtmswXbrgTVgsOA1hrOGZEfeESr1RcH-4Ll4MhY0CxvCuZ8lLrF-2c3qJkRsXplI5URIZv0YrpbCNAfH308lgaC9QlfsFVUoSwaXAQ13pFzhlE8FnQOUcme_p0aVUH4BilHXSCgCGMbKsHcsnvNAqQy5DThqF4HfgTbt72aurSocIX5elieMpj6BZnrbB4yh2Lgrdm5DtHmx_7HPDe7q3CGyu8freMo_o5JsDeRzG-YsRYyjMa1_qqZ4onO6ui8RqIJ1-VWc_gOFMOBRGm7nNh7SN7rUoUAbGfrD9bIollji6hbhpVRwi4OPIZu2aYiiPGA6L4pQatgOAukQ1S7m8j8gi7KmtffhtJGosvypnMRLDOsswhVaKApAbeEJJ9kB1TpAvGoB6dADa-lB3ArQl5G30OKO20MCNBwGVoF-yS7fU_IjJkzHv6wQh-jg';
    const apiUrl = 'https://connect.mailerlite.com/api/subscribers';
    const router = useRouter();
    const {
        handleSubmit, formState: { isValid, errors },
        register,
        clearErrors,
        setError,
        setValue,
        reset
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(yup.object().shape({
            email: yup.string().email("Enter valid email address").required("Please enter the email address"),
        })),
        defaultValues: {
            email: '',
        },
    });

    const createSubscribe = async (subscriberData) => {
        try {
            const response = await axios.post(apiUrl, { ...subscriberData, 'groups': ['111765391792931940'] }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`,
                },
            });

            if (response.status === 201) {
                setShowToast(true)
                setValue('email', '')
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            } else {
                setError('email', {
                    type: 'manual',
                    message: 'Email address already subscribed',
                });
            }
        } catch (error) {
            if (error.response.status === 422) {
                resubscribeEmail(subscriberData, error.response.data.subscriber)
            }
            console.error('Error occurred during subscription:', error);
        }
    }

    const resubscribeEmail = async (subscriberData, id) => {
        try {
            const response = await axios.put(
                `https://connect.mailerlite.com/api/subscribers/${id}`, { ...subscriberData, status: 'active' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setShowToast(true)
                setValue('email', '')
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            } else {
                console.error('Resubscription failed with status code:', response.status);
            }
        } catch (error) {
            console.error('Error during resubscription:', error);
        }
    };

    const onSubmit = (data) => {
        createSubscribe(data)
    }

    useEffect(() => {
        reset({});
    }, [router.pathname])

    return (
        <div className="md:pt-14 pt-8 border-t border-neutral-300">
            {showToast && <Toast message="🎉 Thank You! You're now subscribed to our newsletter. Welcome to the community!" />}

            <Container>
                <div className="flex flex-wrap gap-4 items-center justify-between border-b border-neutral-300 pb-8 mb-4 md:pb-12 md:mb-6">
                    <div>
                        <Heading color="text-black" fontWeight="font-bold" type="h4" className="pb-2">Subscribe to our Newsletter</Heading>
                        <Text fontWeight="font-normal" className="text-neutral-600">Join our mailing list to get a weekly round up of the vlogs and contents!!</Text>
                    </div>
                    <div className="flex items-stretch gap-2">
                        <div className="flex flex-col mb-3 sm:mb-0">
                            <InputBox id="email"
                                labelFor="Input"
                                name="email"
                                variant="Large"
                                dbName={'email'}
                                placeholder="Enter your email"
                                register={register}
                                className="md:w-[247px] grow w-full h-full"
                                errorMessage={errors.email?.message} isError={errors?.email}
                            />
                        </div>
                        <Button label="Subscribe" shadow="shadow-xs" size="small" iconPosition="right" className="h-full flex-none" icon="/images/bell.svg" 
                            onClick={handleSubmit(onSubmit)} />
                    </div>
                </div>

                <div className="flex justify-between md:flex-row flex-col">
                    <div className="md:w-1/2 md:max-w-[410px]">
                        <Logo
                            src="/images/logo.svg"
                            height='h-[75px]'
                            width='w-[210px]'
                            alt="GetRight"
                            onClick={() => router.push('/')}
                        />
                        <Text className="font-medium mt-3 hidden md:block">Protecting what matters to you the most...</Text>
                        <div className="hidden md:flex mt-5 md:mt-9 justify-start gap-4">
                            <a target="_blank" href="https://www.facebook.com/profile.php?id=61555705263713" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                                <Image src="/images/Facebook.svg" layout="fill" alt="Facebbok" />
                            </a>
                            <a target="_blank" href="https://twitter.com/GetRight_Cover" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                                <Image src="/images/Twitter.svg" layout="fill" alt="Twitter" />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/reel/C2uE_3StEEa/?igsh=ejJzNjU1MDNxN2Vv" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                                <Image src="/images/Instagram.svg" layout="fill" alt="Instagram" />
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/99330801/admin/feed/posts/?feedType=following" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                                <Image src="/images/linkeding.svg" layout="fill" alt="Instagram" />
                            </a>
                        </div>
                    </div>
                    <div className="md:w-1/2 flex w-full justify-between mt-4 md:mt-0">
                        <VerticalMenu className=""
                            menuItems={
                                [
                                    {
                                        isActive: false,
                                        title: 'Home',
                                        url: '/'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Features',
                                        url: '/features'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Our USP',
                                        url: '/#usp'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Services',
                                        url: '/services/safety-audits-and-compliance/fire-safety-audit'
                                    }
                                ]
                            }
                        />
                        <VerticalMenu
                            menuItems={
                                [
                                    {
                                        isActive: false,
                                        title: 'Industries',
                                        url: '/industries'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Blogs',
                                        url: 'https://blogs.getrightcover.com/',
                                        newTab: true
                                    },
                                    {
                                        isActive: false,
                                        title: 'About Us',
                                        url: '/about-us'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Contact Us',
                                        url: '/contact-us'
                                    },
                                ]
                            }
                        />
                        <VerticalMenu
                            menuItems={
                                [
                                    {
                                        isActive: false,
                                        title: 'FAQs',
                                        url: '/faq'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Privacy Policy',
                                        url: '/privacy-policy'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Terms of Service',
                                        url: '/terms-of-service'
                                    },
                                    {
                                        isActive: false,
                                        title: 'Partner With Us',
                                        url: '/partner-with-us'
                                    },
                                ]
                            }
                        />
                    </div>

                </div>
                <div className="md:hidden flex mt-5 md:mt-9 justify-center border-t pt-6  gap-4">
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61555705263713" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                        <Image src="/images/Facebook.svg" layout="fill" alt="Facebbok" />
                    </a>
                    <a target="_blank" href="https://twitter.com/GetRight_Cover" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                        <Image src="/images/Twitter.svg" layout="fill" alt="Twitter" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/reel/C2uE_3StEEa/?igsh=ejJzNjU1MDNxN2Vv" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                        <Image src="/images/Instagram.svg" layout="fill" alt="Instagram" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/99330801/admin/feed/posts/?feedType=following" className="relative md:w-[33px] md:h-[33px] w-[34px] h-[38px]">
                        <Image src="/images/linkeding.svg" layout="fill" alt="Instagram" />
                    </a>
                </div>
                <div className="flex flex-wrap gap-4 md:justify-between justify-center items-center md:border-t border-neutral-300 md:mt-9 py-5 md:py-9">
                    <Text fontWeight="font-normal">©2023 GetRight. All rights reserved</Text>
                    <div className="text-center md:text-right">
                        <Text fontWeight="font-normal" className="font-medium"><strong>An Initiative By</strong>: FiveC Risk Solutions Ltd</Text>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
