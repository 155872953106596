import Cookies from 'universal-cookie';
// import { addDays } from 'date-fns';

const cookies = new Cookies();

export const TEAM_COOKIE_NAME = 'SA_TEAM';

export const getAuth = () => cookies.get('AUTH');

// export const getAuthExpiryDt = () => cookies.get('AUTH_EXPIRY_DT');

// export const setAuth = (authObject, expiryValue = addDays(new Date(), 14)) => {
//   cookies.set('AUTH', JSON.stringify(authObject), {
//     path: '/',
//     expires: expiryValue
//   });

//   cookies.set('AUTH_EXPIRY_DT', expiryValue, { path: '/', expires: expiryValue });
//   return authObject;
// };

// export const removeAuth = () => {
//   cookies.remove('AUTH', { path: '/' });
//   cookies.remove('AUTH_EXPIRY_DT', { path: '/' });
// };

export const isInRole = (role, user) => user.roles && user.roles.includes(role);

export const isAuthenticated = (user) => user != null && user.token;

// export const isAdmin = () => {
//   const auth = cookies.get('AUTH');
//   if (auth) { return auth.userRole === 'admin'; }
// };

// export const getCurrentTeamToken = () => cookies.get(TEAM_COOKIE_NAME);

// export const setCurrentTeamToken = (token) => cookies.set(TEAM_COOKIE_NAME, token, { path: '/' });

// export const removeCurrentTeamToken = () => {
//   cookies.remove(TEAM_COOKIE_NAME, { path: '/' });
// };
