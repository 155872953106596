import React, { useState, useContext } from 'react'
import Chatbot from 'react-chatbot-kit'
import config from '../chatBot/Config'
import MessageParser from '../chatBot/MessageParser'
import ActionProvider from '../chatBot/ActionProvider'
import { MessageCircleMore, X } from 'lucide-react';
import { ChatbotDataContext } from '../context/ChatbotContext';

const ChatBot = () => {
  const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);
  const { show } = chatbotUserData

  return (
    <div className='fixed right-4 bottom-4 md:right-12 md:bottom-12 z-[1000]'>
      <div
        className='bg-primary-900 w-12 h-12 rounded-full flex items-center justify-center overflow-hidden cursor-pointer shadow-sm'
        onClick={() =>
          setChatbotUserData({ ...chatbotUserData, service: '', category: '', subCategory: '', state: 'initial', show: !show })
        }
      >
        {
          show ? <X color='#fff' /> : <MessageCircleMore color='#fff' />
        }
      </div>
      {
        show && (
          <div className='absolute right-0 bottom-16 sm:w-[400px] w-[100vw]'>
            <div className='chatbot pl-8 sm:pl-0'>
              <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
              />
            </div>

          </div>
        )
      }

    </div>)
}

export default ChatBot