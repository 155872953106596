import EmptyLayout from "./EmptyLayout";
import UserLayout from "./UserLayout";

const LayoutConfig = [
    {
        path: "/blogs",
        Layout: UserLayout,
    },
    {
        path: "/contact-us",
        Layout: UserLayout,
    },
    {
        path: "/service",
        Layout: UserLayout,
    },
    {
        path: "/industries",
        Layout: UserLayout,
    },
    {
        path: "/",
        Layout: UserLayout,
    },
    {
        path: "/partner-with-us",
        Layout: UserLayout,
    },
];

const getLayoutConfig = (path) => {
    let config = LayoutConfig.find((configItem) => path.includes(configItem.path));
    if (config) return config;
    else return { Layout: EmptyLayout };
};

export { getLayoutConfig };
