export const isProtected = (route) => {
    const protectedRoutes = [];

    return protectedRoutes.includes(route);
};
export const isPublic = (route) => {
    const unprotectedRoutes = [
        "/storybook",
        "/storybook/index.html",
        "/blogs",
        "/contact-us",
        "/partner-with-us",
        "/services",
        "/industries",
        "/features",
        "/about-us",
        "/faq",
        "/privacy-policy",
        "/404",
        "/terms-of-service",
        "/",
        ...(route.includes("/blogs") ? [route] : []),
        ...(route.includes("/services") ? [route] : []),
        ...(route.includes("/industries") ? [route] : []),
    ];
    return unprotectedRoutes.includes(route);
};
