import Text from "atoms/Text";
import Image from "next/image";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react"
import { useRouter } from "next/router";
import useClickOutside from "organisms/Common/ClickOutSide";

const SubMenuList = ({ item, isSubMenu }) => {

  const [show, setShow] = useState(false);
  const isActive = item.active;
  const router = useRouter();
  const ref = useRef();

  const onClick = (item) =>  {
    if(item?.subMenus) {
      setShow(!show)
    } else {
      item?.newTab ? window.open(item.url) : router.push(item.url)
    }
  };

  useClickOutside(ref, () => setShow(false));

  useEffect(() => {setShow(false) }, [router])

  return (
    <li className="relative" ref={ref}>
      <div
        className={`flex items-center gap-1.5 transition-all hover:text-primary-900 cursor-pointer ${isActive && "text-primary-900"}`}
        onClick={() => onClick(item)}>
        {
          item.iconSrc && (
            <div className={`flex-shrink-0 ${item.iconPosition === "right" ? "ml-2.5" : "mr-2.5"}`} >
              <Image
                src={item.iconSrc}
                width={item.iconWidth}
                height={item.iconHeight}
                alt={item.iconAlt}
              />
            </div>
          )
        }

        <div>
          <Text variant="body" className={`${isSubMenu ? '!text-sm' : ''}`}>{item.title}</Text>
          <Text variant="caption" className="text-neutral-600">{item.subTitle}</Text>
        </div>

        {
          item.subMenus && item.subMenus.length > 0 && (
            <ChevronDown size={18} className={`transform transition-all ${show ? 'rotate-180' : ''}`} />
          )
        }
      </div>

      {
        (item.subMenus && show) && (
          <ul
            className={`absolute z-50 right-0 bg-white shadow-xxl top-12 py-4 px-6 min-w-[330px] flex flex-col gap-4 rounded-lg`}
          >
            <div className="absolute -top-5 right-6">
              <Image 
                src='/images/angle-pointer.png'
                width={43}
                height={20}
              />
            </div>
            {item.subMenus.map((subitem) => (
              <SubMenuList item={subitem} isSubMenu={true} />
            ))}
          </ul>
        )
      }
    </li>
  );
};

const SubMenu = ({ menus, className }) => {

  return (
    <ul className={`gap-3 xlg:gap-8 ${className}`}>
      {menus.map((item) => (
        <SubMenuList item={item}></SubMenuList>
      ))}
    </ul>
  );
};

SubMenu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape({}))
};

export default SubMenu;
