import Link from 'next/link';
import PropTypes from 'prop-types';
import Text from './Text';

const VerticalMenu = ({ menuItems = [], listMainClass, listItemClass, ...properties }) => {
	return (
		<nav>
			<ul className={`flex flex-col space-y-2 ${listMainClass}`}>
				{menuItems.map((menu, index) => (
                    <Link href={menu.url}>
                        <a  
                            key={index} 
                            className={`py-2 hover:text-primary-900 transition-all ${listItemClass}`}
                            target={menu?.newTab ? '_blank' : '_self'}
                        >
                            <Text className={`cursor-pointer ${properties.className}`} >
                                  {menu.title}
                            </Text>
                         </a>
                    </Link>
				))}
			</ul>
		</nav >
	)
}

export default VerticalMenu;

VerticalMenu.propTypes = {
    className: PropTypes.string,
    listMainClass: PropTypes.string,
    listItemClass:  PropTypes.string,
    menuItems: PropTypes.array,
};