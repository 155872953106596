import React, { useState } from 'react'
import PropTypes from "prop-types";
import { ChevronDown } from 'lucide-react';
import { useRouter } from 'next/router';

function RenderMenus({ menu, isSubMenu }) {
    const [show, setShow] = useState(false);
    const router = useRouter();
    const isActive = menu?.active;

    const handleMenuClick = (item) => {
        item?.subMenus ? setShow(!show) : router.push(item.url)
    }

    return (
        <>
            <div
                className={`border-b border-neutral-300 py-4 flex justify-between items-center ${isActive ? 'text-primary-900' : ''} ${isSubMenu ? 'px-6 text-sm' : 'text-base'}`}
                onClick={() => handleMenuClick(menu)}
            >
                {menu.title}
                {
                    menu?.subMenus && (
                        <ChevronDown size={18} className={`transform transition-all ${show ? 'rotate-180' : ''}`} />
                    )
                }
            </div>
            {
                (menu?.subMenus && show) && (
                    menu.subMenus.map((subMenu, index) => {
                        return (
                            <RenderMenus key={index} menu={subMenu} isSubMenu={true} />
                        )
                    })

                )
            }
        </>
    )
};

RenderMenus.defaultProps = {
    menu: {},
    isSubMenu: false
};

RenderMenus.propTypes = {
    menu: PropTypes.shape({
        title: PropTypes.string,
        active: PropTypes.bool,
        subMenus: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    isSubMenu: PropTypes.bool
};

export default RenderMenus