const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const UrlParamsReplace = (url, params = {}, queryParams) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  if (queryParams) {
    urlWithPrefix += `?${qs.stringify(queryParams)}`;
  }

  return urlWithPrefix;
};


export const CREATE_CONTACT_US_URL = () => UrlParamsReplace("/contact-us");
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-upload-pre-signed-url");
export const CREATE_CHATBOT_USER_URL = () => UrlParamsReplace("/chatbot-users");
export const UPDATE_CHATBOT_USER_URL = (id) => UrlParamsReplace("/chatbot-users/:id", { id });
export const CREATE_USER_URL = () => UrlParamsReplace("/user");
export const UPDATE_USER_URL = (id) => UrlParamsReplace("/user/:id", { id });
export const CREATE_ADDRESS_URL = () => UrlParamsReplace("/user/address");
export const UPDATE_ADDRESS_URL = (id) => UrlParamsReplace("/user/address/:id", { id });
export const CREATE_QUALIFICATION_URL = () => UrlParamsReplace("/user/qualification");
export const UPDATE_QUALIFICATION_URL = (id) => UrlParamsReplace("/user/qualification/:id", { id })
export const CREATE_PROFESSIONAL_DETAILS_URL = () => UrlParamsReplace("/user/professional");
export const UPDATE_PROFESSIONAL_DETAILS_URL = (id) => UrlParamsReplace("/user/professional/:id", { id });
export const GENERATE_EMAIL_OTP_URL = () => UrlParamsReplace("/authentication/otp");
export const VERIFY_EMAIL_OTP_URL = () => UrlParamsReplace("/authentication/verify-otp");
export const SEND_EMAIL_TO_CONTACT = () => UrlParamsReplace("/email/contact-us");
export const SEND_EMAIL_TO_PARTNERS = () => UrlParamsReplace("/email/partners");
export const SEND_EMAIL_TO_CHATBOT_USER = () => UrlParamsReplace("/email/chatbot");