import React, { createContext, useState } from 'react';

export const ChatbotDataContext = createContext();

export const ChatbotDataProvider = ({ children }) => {
    const [chatbotUserData, setChatbotUserData] = useState({name: "", email:"", state: 'initial', show: false });

    return <ChatbotDataContext.Provider value={{
        chatbotUserData,
        setChatbotUserData
    }}>  {children}</ChatbotDataContext.Provider>

}