import {
  getAuth
} from './identity.service';

import {
  deleteWithAuth, getWithOutAuth, getWithAuth, patchWithAuth, patchWithOutAuth,
  postWithAuth, getWithAuthServerSide, postWithOutAuth, putWithAuth, putWithOutAuth
} from './http.service';

import { CREATE_CONTACT_US_URL, CREATE_CHATBOT_USER_URL, UPDATE_CHATBOT_USER_URL, CREATE_USER_URL, UPDATE_USER_URL, CREATE_ADDRESS_URL, UPDATE_ADDRESS_URL, CREATE_QUALIFICATION_URL, UPDATE_QUALIFICATION_URL, CREATE_PROFESSIONAL_DETAILS_URL, UPDATE_PROFESSIONAL_DETAILS_URL, GENERATE_EMAIL_OTP_URL, VERIFY_EMAIL_OTP_URL, SEND_EMAIL_TO_CONTACT, SEND_EMAIL_TO_PARTNERS, SEND_EMAIL_TO_CHATBOT_USER } from './url.service';


export const createContactUs = (data) => {
  return postWithOutAuth(
    CREATE_CONTACT_US_URL(), data
  );
}

export const createChatbotUser = (data) => {
  return postWithOutAuth(
    CREATE_CHATBOT_USER_URL(), data
  )
}

export const updateChatbotUser = (id, data) => {
  return putWithOutAuth(
    UPDATE_CHATBOT_USER_URL(id), data
  )
}

export const createUser = (data) => {
  return postWithOutAuth(
    CREATE_USER_URL(), data
  );
}

export const updateUser = (id, data) => {
  return putWithOutAuth(
    UPDATE_USER_URL(id), data
  )
}

export const createAddress = (data) => {
  return postWithOutAuth(
    CREATE_ADDRESS_URL(), data
  )
}

export const updateAddress = (id, data) => {
  return putWithOutAuth(
    UPDATE_ADDRESS_URL(id), data
  )
}

export const createQualification = (data) => {
  return postWithOutAuth(
    CREATE_QUALIFICATION_URL(), data
  )
}

export const updateQualification = (id, data) => {
  return putWithOutAuth(
    UPDATE_QUALIFICATION_URL(id), data
  )
}


export const createProfessional = (data) => {
  return postWithOutAuth(
    CREATE_PROFESSIONAL_DETAILS_URL(), data
  )
}

export const updateProfessional = (id, data) => {
  return putWithOutAuth(
    UPDATE_PROFESSIONAL_DETAILS_URL(id), data
  )
}

export const generateEmailOtp = (data) => {
  return postWithOutAuth(
    GENERATE_EMAIL_OTP_URL(), data
  )
}


export const verifyEmailOtp = (data) => {
  return postWithOutAuth(
    VERIFY_EMAIL_OTP_URL(), data
  )
}

export const sendEmailToContact = (data) => {
  return postWithOutAuth(
    SEND_EMAIL_TO_CONTACT(), data
  )
}

export const sendEmailToPartners = (data) => {
  return postWithOutAuth(
    SEND_EMAIL_TO_PARTNERS(), data
  )
}

export const sendEmailToChatbot = (data) => {
  return postWithOutAuth(
    SEND_EMAIL_TO_CHATBOT_USER(), data
  )
}