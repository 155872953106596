import React from "react";
import PropTypes from "prop-types";
import Header from "organisms/Header";
import Footer from "organisms/Footer";
import ChatBot from "../chatBot/Chatbot";

const UserLayout = ({ children }) => {
  return (
    <>
        <Header />
        {children}
        <Footer/>
        <ChatBot />
    </>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node,
};
export default UserLayout;
