import Heading from 'atoms/Heading'
import Image from 'next/image'
import React from 'react'

function BotHeader() {
  return (
    <div className='bg-primary-900 py-4 px-6 md:py-6 md:px-8 flex items-center gap-4'>
        <Image 
            src='/images/short-logo.png'
            width={55}
            height={38}
        />
        <Heading type='h4' className='text-white'>Athena Chatbot</Heading>
    </div>
  )
}

export default BotHeader