import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import { sendEmailToChatbot } from 'services/user.service';

const ResilienceServiceSubCategory = (props) => {
    const { chatbotUserData } = useContext(ChatbotDataContext);

    const getContext = ()=>{
         if(chatbotUserData.category === "Product Features"){
            return "Resilience360"
         }else{
            return "contactUs"  
         }
    }

      
    const getSubject = ()=>{
         const category = chatbotUserData.category;
         if(category === 'Pricing Information'){
            return 'Resilience 360 Pricing Plans'
         }else if(category  === 'Request a Demo'){
            return 'Your Demo Request for Resilience 360'
         }else{
           return 'Resilience 360 Features'
         }
    }



    const sendEmail = async () => {
       const res =  await sendEmailToChatbot({ ...chatbotUserData, context: getContext(), subject: getSubject() });
    }


    useEffect(() => {
        sendEmail()
    }, []);

    useEffect(() => {
        setTimeout(() => {
            props.actions.restartWidget();
        }, 3000);
    }, [])

    return (
        <div className='hidden'></div>
    )
}

export default ResilienceServiceSubCategory