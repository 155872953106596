const Menus = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Features',
        url: '/features'
    },
    {
        title: 'Services',
        subMenus: [
            {
                title: 'Safety Audits and Compliance',
                url: '/services/safety-audits-and-compliance/fire-safety-audit'
            },
            {
                title: 'Risk Assessment and Management',
                url: '/services/risk-assessment-and-management/business-interruption-study'
            },
            {
                title: 'Hazard Identification and Analysis',
                url: '/services/hazard-identification-and-analysis/hazid-(hazard-identification)'
            },
            {
                title: 'Safety Systems and Design',
                url: '/services/safety-systems-and-design/fire-protection-system-design-review'
            },
            {
                title: 'Training, Project Support and Analysis',
                url: '/services/training-project-support-and-analysis/incident-accident-investigation'
            }
        ],
    },
    {
        title: 'Industries',
        url: '/industries'
    },
    {
        title: 'Blogs',
        url: 'https://blogs.getrightcover.com/',
        newTab: true
    },
    {
        title: 'About us',
        url: '/about-us'
    },
    {
        title: 'Contact us',
        url: '/contact-us'
    }
];

export default Menus