import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import Text from 'atoms/Text';
import RadioButton from 'atoms/RadioButton';
import { updateChatbotUser } from 'services/user.service';


const SafetyAudit = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);

    const handleOptionChange = async(event) => {
        if(chatbotUserData.state === "subCategory"){
            setSelectedOption(event.target.value);
            setChatbotUserData({ ...chatbotUserData, state: 'finalState', subCategory: event.target.value })
            await updateChatbotUser(chatbotUserData?.user?.id, { subCategory: event.target.value })

        }
    };

    useEffect(() => {
        if (selectedOption) {
            props.actions.riskMangementSubCategory();
        }
    }, [selectedOption])

    const options = [
        {
            label: 'Fire Safety Audit',
            checked: selectedOption === 'Fire Safety Audit'
        },
        {
            label: 'Electrical Safety Audit',
            checked: selectedOption === 'Electrical Safety Audit'
        },
        {
            label: 'Thermography',
            checked: selectedOption === 'Thermography'
        },
        {
            label: 'Liability Risk Assessment',
            checked: selectedOption === 'Liability Risk Assessment'
        },
        {
            label: 'Risk Inspection',
            checked: selectedOption === 'Risk Inspection'
        }
    ]

    return (
        <div className='mt-2 mb-3 ml-5'>
            <Text variant='bodySmall' className='font-semibold mb-4'>Introduction to Services:</Text>
            <div className='flex flex-col gap-3'>
                {
                    options.map((option, i) => {
                        return (
                            <RadioButton
                                key={i}
                                handleChange={handleOptionChange}
                                value={option.label}
                                isSelected={option.checked}
                                radioLabel={option.label}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SafetyAudit