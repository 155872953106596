import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import { sendEmailToChatbot } from 'services/user.service';

const RiskMangementSubCategory = (props) => {
    const { chatbotUserData } = useContext(ChatbotDataContext);

    useEffect(() => {
        setTimeout(() => {
            props.actions.restartWidget();
        }, 2000);
    }, [])

    const sendEmail = async () => {
        const subject = `GetRight Risk Management Services for ${chatbotUserData.category} - ${chatbotUserData.subCategory}`;
        const service = chatbotUserData.subCategory
        await sendEmailToChatbot({
            ...chatbotUserData,
             context: service,
              subject
        });
    }

    useEffect(() => {
        sendEmail()
    }, []);

    return (
        <div className='hidden'></div>
    )
}

export default RiskMangementSubCategory