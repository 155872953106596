import { createChatBotMessage } from 'react-chatbot-kit';
import Overview from './widgets/Overview';
import UserForm from './widgets/UserForm';
import Service from './widgets/Service';
import { RiskManagementService } from './widgets/RiskManagementService';
import RiskAssessment from './widgets/RiskAssessment';
import SafetyAudit from './widgets/SafetyAudit';
import Avatar from './widgets/Avatar';
import BotHeader from './BotHeader';
import HazardIdentification from './widgets/HazardIdentification'
import SafetySystem from './widgets/SafetySystem'
import TrainingCategory from './widgets/TrainingCategory'
import { ResiliencePro } from './widgets/ResiliencePro';
import RiskMangementSubCategory from './widgets/RiskMangementSubCategory';
import ResilienceServiceSubCategory from './widgets/ResilienceServiceSubCategory';
import ThanksForm from './widgets/ThanksForm';
import RestartWidget from './widgets/RestartWidget';

const config = {
    initialMessages: [
        createChatBotMessage(`Hello! Welcome to GetRight. Glad to have you here.`, {
            widget: "overview"
        }),
    ],

    customStyles: {
        botMessageBox: {
            backgroundColor: '#CFE5FF',
        },
    },
    customComponents: {
        botAvatar: (props) => <Avatar {...props} />,
        header: () => <BotHeader />
    },
    widgets: [
        {
            widgetName: 'overview',
            widgetFunc: (props) => <Overview {...props} />,
        },
        {
            widgetName: 'userForm',
            widgetFunc: (props) => <UserForm {...props} />,
        },
        {
            widgetName: 'thanksForm',
            widgetFunc: (props) => <ThanksForm {...props} />,
        },
        {
            widgetName: 'serviceForm',
            widgetFunc: (props) => <Service {...props} />,
        },
        {
            widgetName: 'riskManagement',
            widgetFunc: (props) => <RiskManagementService {...props} />,
        },
        {
            widgetName: 'fireSafety',
            widgetFunc: (props) => <SafetyAudit {...props} />,
        },
        {
            widgetName: 'riskAssessment',
            widgetFunc: (props) => <RiskAssessment {...props} />,
        },
        {
            widgetName: 'hazardIdentification',
            widgetFunc: (props) => <HazardIdentification {...props} />,
        },
        {
            widgetName: 'safetySystem',
            widgetFunc: (props) => <SafetySystem {...props} />,
        },
        {
            widgetName: 'trainingCategory',
            widgetFunc: (props) => <TrainingCategory {...props} />,
        },
        {
            widgetName: 'resiliencePro',
            widgetFunc: (props) => <ResiliencePro {...props} />,
        },
        {
            widgetName: 'riskMangementSubCategory',
            widgetFunc: (props) => <RiskMangementSubCategory {...props} />,
        }, {
            widgetName: 'resilienceServiceSubCategory',
            widgetFunc: (props) => <ResilienceServiceSubCategory {...props} />,
        },
        {
            widgetName: 'restartWidget',
            widgetFunc: (props) => <RestartWidget {...props} />,
        }
    ]
};

export default config;