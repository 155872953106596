import Logo from 'atoms/Logo'
import React from 'react'
import RenderMenus from './RenderMenus';
import { X } from 'lucide-react';
import PropTypes from "prop-types";

function MobileNavigation({ menus, onClose }) {
    return (
        <div className='fixed bg-neutral-600 left-0 top-0 w-full h-full z-[1999] animation-slide'>
            <div className='bg-white max-w-[80%] w-full h-full'>
                <div className='w-full relative px-4 pt-2'>
                    <div className='bg-white absolute p-2 top-3 -right-10'>
                        <X onClick={onClose} />
                    </div>
                    <Logo
                        src="/images/logo.svg"
                        height='h-[50px] lg:h-[75px]'
                        width='w-[145px] lg:w-[210px]'
                        alt="GetRight"
                        onClick={() => router.push('/')}
                    />
                </div>
                <div className='py-6 px-4'>
                    {
                        menus.map((menu, i) => {
                            return (
                                <RenderMenus key={i} menu={menu} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

MobileNavigation.defaultProps = {
    menus: [],
    onClose: () => {}
};

MobileNavigation.propTypes = {
    menus: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        active: PropTypes.bool,
        subMenus: PropTypes.arrayOf(PropTypes.shape({}))
    })),
    onClose: PropTypes.func
};

export default MobileNavigation