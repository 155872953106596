import Image from "next/image";
import PropTypes from "prop-types";

const Logo = ({ className, width, height, alt, src, onClick }) => {
  return (
    <div
      className={`relative cursor-pointer select-none ${width} ${height} ${className}`}
      onClick={onClick}
    >
      <Image
        src={src}
        alt={alt}
        layout="fill"
      />
    </div>
  );
};

Logo.defaultProps = {
  className: "",
  width: "",
  height: "",
  alt: "",
  src: "",
  onClick: "",
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
};

export default Logo;
