import Button from 'atoms/Button';
import Message from 'atoms/Message';
import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext';
import { createChatbotUser } from 'services/user.service'

const RestartWidget = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);

    const createUser = async()=>{
        const response = await createChatbotUser({
            fullName: chatbotUserData?.name,
            email: chatbotUserData?.email,
            phoneNumber: chatbotUserData?.mobile
          });

          if(response.status){
            setChatbotUserData({ ...chatbotUserData,  state: "service", user: response.entity });
          }
    }

    
    useEffect(() => {
        if (selectedOption === "YES") {
            props.actions.afterUserForm();
            createUser()
        } else if (selectedOption === "NO") {
            props.actions.finalWidget();
        }
    }, [selectedOption])


    const submitReplay = (replay) => {
        if (chatbotUserData?.state === 'finalState') {
            setSelectedOption(replay);
            setChatbotUserData({...chatbotUserData, state:'userForm'})
        }
    }

    return (
        <div className='ml-5'>
            <div className='my-5 flex justify-end items-center gap-2'>
                <Button
                    label='Yes'
                    size='extraSmall'
                    style='ghost'
                    onClick={() => submitReplay('YES')}
                />
                <Button
                    label='No'
                    size='extraSmall'
                    style='ghost'
                    onClick={() => submitReplay('NO')}
                />
            </div>
        </div>
    )
}

export default RestartWidget