import React from "react";
import PropTypes from "prop-types";

function FormGroup({ required, name, type, label, placeholder, register, errors, inputClass, disabled, className, appendData, labelWidth, variant }) {
       
    const variantStyle = {
        default: "px-4 py-[10px]",
        small: "p-2",
        extraSmall: "py-1.5 leading-1 px-4 text-sm",
        large: "px-4 py-3",
    };

    const labelStyle = {
        default: "text-sm",
        small: "text-sm",
        extraSmall: "text-xs",
        large: "text-base",
    }

    return (
        <div className={`flex ${className}`}>
            <label className={`flex-none ${labelStyle[variant]}  ${labelWidth}`} htmlFor={name}>
                {label}
                {required && <span className="text-red-700 ml-1">*</span>}
            </label>
            <div className="relative flex-1">
                <input
                    id={name}
                    type={type}
                    placeholder={placeholder || label}
                    disabled={disabled}
                    {...register(name)}
                    className={`rounded-md focus:outline-none px-4 border border-neutral-300 ${variantStyle[variant]} ${inputClass} ${errors[name] ? "is-invalid" : ""}`}
                />
                {errors[name] && <div className={`absolute text-xs -bottom-5 text-error-100`}> {errors[name].message} </div>}
            </div>
            {appendData && appendData}
        </div>
    );
};

FormGroup.defaultProps = {
    required: false, 
    name: 'name', 
    type: 'text', 
    label: 'label', 
    placeholder: 'Placeholder', 
    register: () => {}, 
    errors: [], 
    inputClass: "w-full", 
    disabled: false, 
    className: 'gap-4 pb-7', 
    appendData: '', 
    labelWidth: "w-40",
    variant: 'default'
};

FormGroup.propTypes = {
    required: PropTypes.bool, 
    name: PropTypes.string, 
    type: PropTypes.string, 
    label: PropTypes.string, 
    placeholder: PropTypes.string, 
    register: PropTypes.func, 
    errors: PropTypes.arrayOf(), 
    inputClass: PropTypes.string, 
    disabled: PropTypes.bool, 
    className: PropTypes.string, 
    appendData: PropTypes.string, 
    labelWidth: PropTypes.string,
    variant: PropTypes.oneOf['default', 'small', 'extraSmall', 'large']
};

export default FormGroup;
