import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import Text from 'atoms/Text';
import RadioButton from 'atoms/RadioButton';
import { updateChatbotUser, sendEmailToChatbot } from 'services/user.service';

export const ResiliencePro = (props) => {
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = async (event) => {
        if (chatbotUserData?.state === "category") {
            setSelectedOption(event.target.value);
            setChatbotUserData({ ...chatbotUserData, state: 'finalState', category: event.target.value })
            await updateChatbotUser(chatbotUserData?.user?.id, { category: event.target.value })
        }
    };

    useEffect(() => {
        if (selectedOption) {
            props.actions.resilienceServiceCategory();
        }
    }, [selectedOption])

    const options = [
        {
            label: 'Product Features',
            checked: selectedOption === 'Product Features'
        },
        {
            label: 'Pricing Information',
            checked: selectedOption === 'Pricing Information'
        },
        {
            label: 'Request a Demo',
            checked: selectedOption === 'Request a Demo'
        }
    ]

    return (
        <div className='mt-2 mb-3 ml-5 flex flex-col'>
            <Text variant='bodySmall' className='font-semibold mb-4'>Introduction to Services:</Text>
            <div className='flex flex-col  gap-3'>
                {
                    options.map((option, i) => {
                        return (
                            <RadioButton
                                key={i}
                                handleChange={handleOptionChange}
                                value={option.label}
                                isSelected={option.checked}
                                radioLabel={option.label}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
