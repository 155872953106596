import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import Button from 'atoms/Button';
import { updateChatbotUser } from 'services/user.service';
const Service = (props) => {
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (selectedOption === "Risk Management Services") {
            props.actions.riskMangementService();
        } else if (selectedOption === "Resilience 360") {
            props.actions.resilienceService();
        }
    }, [selectedOption])


    const submitServices = async (service) => {
        if (chatbotUserData?.state === 'service') {
            setSelectedOption(service);
            setChatbotUserData({ ...chatbotUserData, state: 'category', service: service })
            await updateChatbotUser(chatbotUserData?.user?.id, { service })
        }
    }


    return (
        <div className='flex flex-col gap-2 items-end w-max ml-auto my-3'>
            <Button
                label='Risk Management Services'
                size='extraSmall'
                style='ghost'
                onClick={() => { submitServices('Risk Management Services'); }}
            />
            <Button
                label='Resilience 360'
                size='extraSmall'
                style='ghost'
                onClick={() => { submitServices('Resilience 360') }}
                className='w-max'
            />
        </div>
    )
}

export default Service