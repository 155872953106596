import React from 'react'

function Message({ message }) {
  return (
    <div className='bg-primary-100 py-2 px-4 rounded-lg rounded-tl-sm text-sm'>
        {message}    
    </div>
  )
}

export default Message