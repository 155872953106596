import PropTypes from "prop-types";

const Heading = ({ children, type, className, fontWeight, color, fontSize }) => {
  const headingStyles = {
    h1: "md:text-5xl text-3xl",
    h2: "md:text-4xl text-2xl",
    h3: "md:text-3xl text-xl",
    h4: "md:text-2xl text-lg",
    h5: "md:text-xl text-lg",
    h6: "md:text-lg text-sm",
  };

  const Type = headingStyles[type] ? type : "h1";

  return (
    <Type className={`${fontSize || headingStyles[Type] } ${className} ${fontWeight} ${color}` }>{children}</Type>
  );
};

Heading.defaultProps = {
  type: "h1",
  className: "",
  color: "text-black",
  fontSize: ''
};

Heading.propTypes = {
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  className: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOf([
    "font-thin",
    "font-hairline",
    "font-extralight",
    "font-light",
    "font-normal",
    "font-medium",
    "font-semibold",
    "font-bold",
    "font-extrabold",
    "font-black",
  ]),
  color: PropTypes.oneOf([
    "text-primary-900",
    "text-black",
    "text-white",
    "text-neutral-500"
  ]),
};

export default Heading;
