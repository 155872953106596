import React, { useEffect } from 'react'

const ThanksForm = (props) => {

    useEffect(() => {
        setTimeout(() => {
            props.actions.afterUserForm();
        }, 500);
    }, [])

    return (
        <div className='hidden'>ThanksForm</div>
    )
}

export default ThanksForm