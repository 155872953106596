import Logo from "atoms/Logo";
import SubMenu from "molecules/SubMenu";
import Button from "atoms/Button";
import Container from "atoms/Container";
import { AlignJustify } from "lucide-react";
import { useRouter } from 'next/router'
import Menus from "@/components/Helper/constants";
import MobileNavigation from "molecules/MobileNavigation";
import { useEffect, useState } from "react";

const Header = () => {

    const [showMobileNav, setShowMobileNav] = useState(false);
    const router = useRouter();

    const menusData = Menus.map((item) => {
        const isActive = router.pathname.includes(item.url) && item.url !== '/';
        return Object.assign(item, { active: isActive });
    });

    useEffect(() => {
        setShowMobileNav(false)
    }, [router])

    return (
        <div className="shadow-md bg-white">
            <Container className="">
                <div className="flex justify-between items-center">
                    <Logo
                        src="/images/logo.svg"
                        height='h-[50px] lg:h-[75px]'
                        width='w-[145px] lg:w-[210px]'
                        alt="GetRight"
                        onClick={() => router.push('/')}
                    />
                    <div className="flex items-center gap-2 lg:gap-8">
                        <SubMenu
                            menus={menusData}
                            className='hidden lg:flex'
                        />
                        <Button
                            label="Partner with Us!"
                            size="small"
                            shadow="extraSmall" onClick={() => router.push(`/partner-with-us`)}
                        />
                        {
                            showMobileNav && (
                                <MobileNavigation menus={menusData} onClose={() => setShowMobileNav(false)}/>
                            )
                        }
                        <AlignJustify className='block lg:hidden' onClick={() => setShowMobileNav(true)} />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;
