import Button from 'atoms/Button';
import Message from 'atoms/Message';
import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext';

const Overview = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);

  useEffect(() => {
    if (selectedOption === "YES") {
      props.actions.initialAction();
    }else if(selectedOption === "NO"){
      setChatbotUserData({...chatbotUserData, service: '', category: '', subCategory: '', state: 'initial', show: false});
    }
  }, [selectedOption])


  const submitReplay = (replay)=>{
     if(chatbotUserData?.state === 'initial'){
      setSelectedOption(replay);
      setChatbotUserData({...chatbotUserData, state : "userForm"})
     }
  }

  return (
    <div className='ml-5'>
      <Message message='We would love to understand your requirements but before we proceed, can you tell us about yourself?' />
      <div className='my-5 flex justify-end items-center gap-2'>
        <Button
          label='Yes'
          size='extraSmall'
          style='ghost'
          onClick={() => submitReplay('YES')}
        />
        <Button
          label='No'
          size='extraSmall'
          style='ghost'
          onClick={() => submitReplay('NO')}
        />
      </div>
    </div>
  )
}

export default Overview