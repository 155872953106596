import { useState, useEffect, useContext } from 'react'
import { ChatbotDataContext } from '../../context/ChatbotContext'
import Text from 'atoms/Text';
import RadioButton from 'atoms/RadioButton';
import { updateChatbotUser } from 'services/user.service';

export const RiskManagementService = (props) => {
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = async(event) => {
        if(chatbotUserData?.state === 'category'){
            setSelectedOption(event.target.value);
            setChatbotUserData({ ...chatbotUserData, state: 'subCategory',  category: event.target.value })
            const res = await updateChatbotUser(chatbotUserData?.user?.id, { category: event.target.value })
        }
    };

    useEffect(() => {
        if (selectedOption === "Safety Audits and Compliance") {
            props.actions.fireSafetyCategory();
        } else if (selectedOption === 'Risk Assessment and Management') {
            props.actions.riskAssessmentCategory();
        } else if (selectedOption === 'Hazard Identification and Analysis') {
            props.actions.hazardIdentificationCategory();
        } else if (selectedOption === 'Safety Systems and Design') {
            props.actions.safetySystemCategory();
        } else if (selectedOption === 'Training, Project Support and Analysis') {
            props.actions.trainingCategory();
        }

    }, [selectedOption])

    const options = [
        {
            label: 'Safety Audits and Compliance',
            checked: selectedOption === 'Safety Audits and Compliance'
        },
        {
            label: 'Risk Assessment and Management',
            checked: selectedOption === 'Risk Assessment and Management'
        },
        {
            label: 'Hazard Identification and Analysis',
            checked: selectedOption === 'Hazard Identification and Analysis'
        },
        {
            label: 'Safety Systems and Design',
            checked: selectedOption === 'Safety Systems and Design'
        },
        {
            label: 'Training, Project Support and Analysis',
            checked: selectedOption === 'Training, Project Support and Analysis'
        }
    ]

    return (
        <div className='mt-2 mb-3 ml-5 flex flex-col'>
            <Text variant='bodySmall' className='font-semibold mb-4'>Introduction to Services:</Text>
            <div className='flex flex-col  gap-3'>
                {
                    options.map((option, i) => {
                        return (
                            <RadioButton
                                key={i}
                                handleChange={handleOptionChange}
                                value={option.label}
                                isSelected={option.checked}
                                radioLabel={option.label}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
