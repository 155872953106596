import PropTypes from "prop-types";

const Text = ({
  variant,
  fontSize,
  fontWeight,
  children,
  className,
  onClick,
  align
}) => {
  const TextStyle = {
    bodyLarge: "text-base md:text-lg",
    body: "md:text-base text-sm",
    bodySmall: "text-sm",
    caption: "text-xs",
  };
  return (
    <p
      className={`${fontSize || TextStyle[variant] || TextStyle.body} ${
        fontWeight || ""
      } ${className || ""} ${align} `}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export default Text;

Text.propTypes = {
  variant: PropTypes.oneOf(["body", "bodySmall", "caption"]),
  children: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOf([
    "font-thin",
    "font-hairline",
    "font-extralight",
    "font-light",
    "font-normal",
    "font-medium",
    "font-semibold",
    "font-bold",
    "font-extrabold",
    "font-black",
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.string
};

Text.defaultProps = {
  variant: "body",
  fontSize: "",
  fontWeight: "normal",
  children: "",
  className: "",
  onClick: () => {},
  align: 'text-justify'
};
