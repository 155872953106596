import React, { useState, useContext, useEffect } from 'react';
import { ChatbotDataContext } from '../../context/ChatbotContext'
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupValidator } from '@/components/Utilities/yup-validator';
import FormGroup from 'molecules/FormGroup';
import Button from 'atoms/Button';
import Text from 'atoms/Text';
import { createChatbotUser } from 'services/user.service'

const UserForm = (props) => {
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {
        register,
        handleSubmit,
        errors,
        watch
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                name: yup.string().required("Please enter your Name!"),
                mobile: yup.string().required("Please enter your Mobile number!"),
                email: yup.string().required("Please enter your E-mail!"),
            })
        )
    });

    const onSave = async (data) => {
        if (chatbotUserData?.state === "userForm") {
            setFormSubmitted(true);            
            const response = await createChatbotUser({
                fullName: data?.name,
                email: data.email,
                phoneNumber: data?.mobile
              });
              console.log('response', response);
            if(response.status){
                setChatbotUserData({ ...chatbotUserData,...data,  state: "service", user: response.entity });
                props.actions.ThanksForm(data.name);
            }

            }
    };

    const formFields = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your name',
            isError: !!errors?.name,
            
        },
        {
            label: 'Mobile Number',
            name: 'mobile',
            type: 'text',
            placeholder: 'Enter you mobile number',
            isError: !!errors?.mobile
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email',
            isError: !!errors?.email
        },
    ]

    return (
        <div className="border p-4 max-w-[270px] ml-auto my-3">
            <div className='flex flex-col gap-4'>
                {
                    formFields.map((field, i) => {
                        return (
                            <FormGroup
                                key={i}
                                label={field.label}
                                name={field.name}
                                register={register}
                                errors={errors}
                                placeholder={field.placeholder}
                                className='flex-col gap-1'
                                variant='extraSmall'
                                type={field.type}
                                required
                            />
                        )
                    })
                }
                <Button
                    label='Submit'
                    size='extraSmall'
                    onClick={handleSubmit(onSave)}
                />
            </div>
        </div>
    );
};

export default UserForm;
