import React, { useState, useContext } from 'react';
import { ChatbotDataContext } from '../context/ChatbotContext';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const { chatbotUserData, setChatbotUserData } = useContext(ChatbotDataContext);

    const initialAction = () => {
        const message = createChatBotMessage('Alright! Let’s add your details!', {
            widget: "userForm"
        });
        updateState(message, "users")
    }

    const ThanksForm = (name) => {
        const message = createChatBotMessage(`Thanks for sharing your information, ${name}!`, {
            widget: "thanksForm"
        });

        updateState(message, "thanksForm")
    }

    const afterUserForm = () => {
        const message = createChatBotMessage(`At GetRight, we offer various services. Would you like to explore our Risk Management Services or inquire about our product Resilience 360?`, {
            widget: "serviceForm"
        });

        updateState(message, "serviceForm")
    }


    const riskMangementService = () => {
        const message = createChatBotMessage(' Fantastic! Our Risk Management Services are designed to help businesses navigate uncertainties. What specific Risk Management Service are you interested in?', {
            widget: "riskManagement"
        });

        updateState(message, "riskManagement")
    }


    const fireSafetyCategory = () => {
        const message = createChatBotMessage('Great! Request you to please share the service you wish to enquire about.', {
            widget: "fireSafety"
        });

        updateState(message, "fireSafety")
    }

    const riskAssessmentCategory = () => {
        const message = createChatBotMessage('Great! Request you to please share the service you wish to enquire about.', {
            widget: "riskAssessment"
        });

        updateState(message, "riskAssessment")
    }

    const hazardIdentificationCategory = () => {
        const message = createChatBotMessage('Great! Request you to please share the service you wish to enquire about.', {
            widget: "hazardIdentification"
        });

        updateState(message, "hazardIdentification")
    }


    const safetySystemCategory = () => {
        const message = createChatBotMessage('Great! Request you to please share the service you wish to enquire about.', {
            widget: "safetySystem"
        });

        updateState(message, "safetySystem")
    }

    const trainingCategory = () => {
        const message = createChatBotMessage('Great! Request you to please share the service you wish to enquire about.', {
            widget: "trainingCategory"
        });

        updateState(message, "trainingCategory")
    }

    const resilienceService = () => {
        const message = createChatBotMessage('Excellent! Resilience 360 is a powerful solution. How can we assist you with Resilience 360?', {
            widget: "resiliencePro"
        });

        updateState(message, "resiliencePro")
    }



    const resilienceServiceCategory = () => {
        const message = createChatBotMessage(`Thanks for your interest! We'll send you the requested information on ${chatbotUserData?.category}.`, {
            widget: "resilienceServiceSubCategory"
        });
        updateState(message, "resilienceServiceSubCategory")
    }


    const riskMangementSubCategory = () => {
        const message = createChatBotMessage(`Thanks for your interest! We'll send you the requested information on ${chatbotUserData?.subCategory}.`, {
            widget: "riskMangementSubCategory"
        });
        updateState(message, "riskMangementSubCategory")
    }

    const restartWidget = () => {
        const message = createChatBotMessage(`Do you wish to restart the chat?`, {
            widget: "restartWidget"
        });

        updateState(message, "restartWidget")
    }


    const finalWidget = () => {
        const message = createChatBotMessage(` Thank you for chatting with GetRight. If you have any more questions or need further assistance, feel free to ask. Have a great day!`);
        updateState(message, "final")

        setTimeout(() => {
            setChatbotUserData({ ...chatbotUserData, service: '', category: '', subCategory: '', state: 'initial', show: false });
        }, 40000);
    }


    const updateState = (message, checker) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
            checker,
        }))
    }


    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        initialAction,
                        afterUserForm,
                        riskMangementService,
                        fireSafetyCategory,
                        riskMangementSubCategory,
                        riskAssessmentCategory,
                        hazardIdentificationCategory,
                        safetySystemCategory,
                        trainingCategory,
                        resilienceService,
                        resilienceServiceCategory,
                        finalWidget,
                        ThanksForm,
                        restartWidget
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;