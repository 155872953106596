import React from 'react'
import PropTypes from "prop-types";
import Text from './Text';
import { Ban, CheckCircle2 } from 'lucide-react';

function Toast({ variant, message }) {
    
    const variantStyle = {
        success: {
            style: 'bg-success-50 text-success-100',
            icon: <CheckCircle2 />
        },
        failure: {
            style: 'bg-error-50 text-error-100',
            icon: <Ban />
        }
    }

    return (
        <div className={`fixed top-20 right-4 z-50 px-4 py-5 rounded flex gap-2 items-center overflow-hidden ${variantStyle[variant].style}`}>
            {variantStyle[variant].icon}
            <Text variant='bodySmall'>{message}</Text>
        </div>
    )
};

Toast.defaultProps = {
    variant: 'success',
    message: 'This is a success message'
};

Toast.propTypes = {
    variant: PropTypes.oneOf(['success', 'failure']),
    message: PropTypes.string
};

export default Toast