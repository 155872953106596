import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith/isomorphic';
import { getLayoutConfig } from "@/components/Layout/LayoutConfiguration";
import WithAuth from '@/components/Auth/WithAuth';
import 'react-chatbot-kit/build/main.css'
import '../styles/globals.css';
import { ChatbotDataProvider } from 'components/context/ChatbotContext.js';

function MyApp({ Component, pageProps, router, flagsmithState }) {
  let layoutConfig = getLayoutConfig(router.pathname);
  const { Layout: FetchedLayout, overrides } = layoutConfig;
  return (
    <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
      <WithAuth router={router}>
        <ChatbotDataProvider>
          <FetchedLayout children={<Component {...pageProps} />} {...overrides} />
        </ChatbotDataProvider>
      </WithAuth>
    </FlagsmithProvider>

  );
}

MyApp.getInitialProps = async () => {
  await flagsmith.init({
    environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
  });
  return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
